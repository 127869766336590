/* ProductCard.css */
/* .product-card {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
    padding: 20px;
    margin: 10px;
  } */
  
  .product-card img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }
  
  .product-card h2 {
    font-size: 20px;
    margin: 10px 0;
  }
  
  .product-card .description {
    font-size: 14px;
    color: #777;
  }
  
  .product-info {
    margin: 20px 0;
  }
  
  .product-info .price {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .product-info .original-price {
    text-decoration: line-through;
    color: #999;
  }
  
  .product-info .discounted-price {
    color: #e74c3c;
  }
  
  .product-info p {
    font-size: 14px;
    color: #555;
    margin: 5px 0;
  }
  
  button {
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #2980b9;
  }
  


  /* ProductCard.css */
.product-card {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
    padding: 20px;
    margin: 10px;
    overflow: hidden; /* Hide overflow for the image container */
  position: relative;

  }
  
  .image-container {
    position: relative;
  }
  
  .image-container img {
    width: 100%;
    max-height: 200px;
    object-fit: contain;
    /* display: block; */
  }
  
  .image-container button {
    position: absolute;
    top: 50%;
    width: 11%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.7);
    border: none;
    color: #333;
    padding: 10px;
    cursor: pointer;
  }
  
  .image-container button:hover {
    background: rgba(255, 255, 255, 0.9);
  }
  
  .image-container button.prev {
    left: 0;
  }
  
  .image-container button.next {
    right: 0;
  }
  
  /* Add a smooth scroll effect */
  .image-scroll {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
  }
  
  .image-scroll img {
    flex-shrink: 0;
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    scroll-snap-align: start;
  }
  
  /* Optional: Hide the horizontal scrollbar */
  .image-scroll::-webkit-scrollbar {
    display: none;
  }
  

  /* ProductDisplay.css */
/* ...Previous styles... */

/* Style for the "Previous" button */
.scroll-button.prev {
  float: left;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: none;
  padding: 5px 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

/* Add a "Previous" arrow icon using CSS content property */
.scroll-button.prev::before {
  content: '\2190'; /* Unicode arrow character (left arrow) */
  font-size: 24px;
  margin-right: 5px;
}

.scroll-button.prev:hover {
  background-color: #0056b3;
}

/* Style for the "Next" button */
.scroll-button.next {
  float: right;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: none;
  padding: 5px 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* Add a "Next" arrow icon using CSS content property */
.scroll-button.next::after {
  content: '\2192'; /* Unicode arrow character (right arrow) */
  font-size: 24px;
  margin-left: 5px;
}

/* .scroll-button.next:hover {
  background-color: #0056b3;
} */



.button-container {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 10px; 
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  
  margin-top: 10px;
}

/* Update button styles */
.update-button {
  background-color: #4caf50; 
  color: white; 
  border: none; 
  padding: 10px 20px; 
  cursor: pointer; 
  border: 1px solid white;
}

.update-button:hover {
  background-color: #45a049; /* Darker green color on hover */
}

/* Delete button styles */
.delete-button {
  background-color: #f44336; /* Red background color */
  color: white; 
  border: none; 
  padding: 10px 20px;
  cursor: pointer; 
  border: 1px solid white;

}

.delete-button:hover {
  background-color: #d32f2f; /* Darker red color on hover */
}





















/* ------------------------------------------------------------------------------------------------------- */


/* Main Layout CSS */

#notification{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding : 20px;
  height: 60px;
  margin-bottom:24px;
  background-color: aliceblue;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -24px;
  border: 1px solid #ccc;
}

@media (max-width:480px) {
  #dropdownMenuLink{
    display: none;
  }
}








#content{
  margin: 20px 16px;
  padding: 24px;
}
@media (max-width:768px) {
  #content{
    margin: 10px;
    padding: 3px;
  }
  
}