/* ProductDetails.css */
.product-details-container {
    padding: 20px;
  }
  
  .product-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .product-images {
    width: 50%;
    margin-right: 20px;
    height: 300px;
    /* background-color: red; */
  }
  
  /* .product-images img {
  
    height: 500px;
    border: 1px solid #ccc;
    padding: 5px;
    margin-bottom: 10px;
    width: 100%;
  }
   */
  .product-info {
    width: 50%;
  }
  
  .product-info h1 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .product-info .description {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
  }
  
  .product-info .price {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .product-info .original-price {
    text-decoration: line-through;
    margin-right: 10px;
    color: #999;
  }
  
  .product-info .discounted-price {
    color: #e74c3c;
  }
  
  .product-info .category,
  .product-info .brand,
  .product-info .stock {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .product-info .add-to-cart-button {
    display: block;
    width: 100%;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 0;
    cursor: pointer;
    font-size: 16px;
  }
  
  .product-info .add-to-cart-button:hover {
    background-color: #0056b3;
  }
  

  .image-container img{
    width: 100%;
  height: 100%;
  }