/* BulkProducts.css */

/* Container styles */
.form-container {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
}

/* Header styles */
.form-header {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

/* Form group styles */
.form-group {
  margin: 10px 0;
}

/* File input styles */
.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
}

.form-input:focus {
  border-color: #007bff;
}

/* Upload button styles */
.upload-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: #0056b3;
}

.table-container {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}



table th, table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

table th {
  background-color: #007bff;
  color: #fff;

}

table th, table td input {
  width: 100%;
  border: none;
  padding: 5px;
  outline: none;
}

.download-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 20px;
  display: block;
  width: 100%;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #0056b3;
}

/* Table styles */
.table-container {
  max-height: 400px; 
  overflow-y: scroll;
  border: 1px solid #ccc;
  border-radius: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #007bff;
  color: #fff;
  position: sticky;
  top: 0; 
  z-index: 1;
}

table th input,
table td input {
  width: 100%;
  border: none;
  padding: 5px;
  outline: none;
}

.download-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 20px;
  display: block;
  width: 100%;
  transition: background-color 0.3s;
  
}

.download-button:hover {
  background-color: #0056b3;
}


.image-list {
  display: flex;
  flex-wrap: wrap;
}

.image-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
}

.image-container img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border: 1px solid #ddd; 

}


.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ff4d4f; 
  color: #fff; 
  border: none;
  /* padding: 2px; */
  font-size: 16px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  /* opacity: 1; */
  border: 1px solid black;

}

.image-container:hover .delete-button {
  opacity: 1;
  
}



.delete-button .anticon svg {
  margin-top: -17px; 
  color: black;
  font-size: 22px;
  /* border: 1px solid black; */
  padding:2px;
}