.cardMainDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* grid-gap: 20px;  */
    grid-column-gap: 20px;
    flex-direction: row;
    margin-bottom: 15px;
}



.column-class-sl-no{
    width: 5%;
}
.column-class-name{
    width: 20%;
}
.column-class-product{
    width: 3%;
}
.column-class-address{
    width: 25%;
}
.column-class-status{
    width: 10%;
}