/* SearchBar.css */

/* Search bar container */
.search-bar-container {
    position: relative;
    width: 100%;

  }
  
  /* Search input */
  .search-bar {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
    margin-bottom: -100px;
  }
  
  .suggestions-container {
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 400px;
    /* overflow-y: auto; */
    display: block;
    z-index: 999;

  }
  
  .suggestion {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .suggestion:hover {
    background-color: #f0f0f0;
  }
  
  


  .sn-column{
    width: 50px;
  }