.coupon-form-container {
    max-width: 500px;
    margin: auto;
    padding: 20px;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
  }
  
  .ant-form-item {
    margin-bottom: 16px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  