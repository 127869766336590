:root {
  --color-212529: #212529;
  --color-ffd333: #ffd333;
  --color-47ad24: #47ad24;
  --color-e52e2e: #e52e2e;
  --color-828599: #828599;
  --color-3d464d: #ed464d;
  --color-c3d4da: #ced4da;
}

.green {
  color: var(--color-47ad24);
  font-size: 14px;
  font-family: Roboto !important;
}
.red {
  color: var(--color-212529);
  font-size: 14px;
  font-family: Roboto !important;
}
.quill {
  background-color: white;
}

.form-control {
  box-shadow: none;
  border-color: var(--color-c3d4da);
}
.title {
  font: 28px;
  font-family: Roboto !important;
  color: var(--color-212529);
}

.sub-title {
  font: 24px;
  font-family: Roboto !important;
  color: var(--color-212529);
}
.desc {
  font: 14px;
  font-family: Roboto !important;
  color: var(--color-828599);
}






/* -------------------------------------------------------------------------------------------------------------------------- */

/* Other Pages CSS */
table{
  padding: 1%;
  text-align: center;
  font-size: larger;
}

.order-id-column {
  width: 6%;
}
.customer-name-column{
  width: 25%;
  text-align: center;
}
.product-column{
  width: 25%;
}
.amount-column{
  width: 15%;
}
.order-details-modal{
  padding: 20px;
}










/* ----------------------------------------------------------------------------------------------------------------------------------- */

/* Customer CSS */

.column-class-sl-no{
  width: 6%;
  text-align: center;
}
.column-class-name{
  width: 25%;
}
.column-class-email{
  width: 25%;
}
.column-class-mobile{
  width: 15%;
}






/* -------------------------------------------------------------------------------------------------------- */
/* Enquiry Css */

.column-class-sno{
  width: 6%;
}